@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.wrapper1 {
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0);
    position: relative;
}

.wrapper1:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.04;
  }

.wrapper-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
}


.wrapper-content-left {
    width: 350px;
    text-align: center;
}

.wrapper-content-left img {
    width: 100%;
}

.wrapper-content-right {
    width: 600px;
    margin: auto;
}


.wrapper-content-right h1 {
    text-transform: uppercase;
    color: rgb(253, 146, 5);
    font-size: 4rem;
    letter-spacing: 8px; 
    padding: 1rem 0;
}



@media screen and (max-width:1250px)  {
    .wrapper-content-right {
        width: 100%;
        padding: 1rem 2rem;
    }

    .wrapper-content-right h1, .wrapper-content-right h2, .wrapper-content-right p {
        text-align: center;
    }

    .wrapper-content-right h2 {
        font-size: 2rem;
    }

}

@media screen and (max-width:940px) {
    .wrapper-content-left {
        width: 100vw;
    }
    .wrapper-content-left img {
        padding: 2rem;
    }
}

